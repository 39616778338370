import './site-dropdown.scss';
import scrollIntoView from 'scroll-into-view-if-needed';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Select } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useCallback } from 'react';
import Site from '../../interfaces/ISite';

const { Option, OptGroup } = Select;

export interface ISiteDropdownProps {
    sites: Site[];
    selectedSite: Site | undefined;
    closest?: Site;
    value?: number;
    placeholder: string;
    closestVenueLabel: string;
    onChange?: (value: number, option: object[] | object) => void;
}

const SiteDropdown: React.FunctionComponent<ISiteDropdownProps> = (props) => {
    const scrollRef: React.RefObject<HTMLElement> = useMemo(() => {
        return React.createRef();
    }, []);

    const SiteItem = (site: Site, nearest = false): React.ReactElement => {
        return (
            <Option className="zl-site-option" value={site.siteId} key={`${site.siteId}${nearest ? `-nearest` : ``}`}>
                {site.name}
            </Option>
        );
    };

    const CountryGroup = useCallback((countrySites: Site[]): React.ReactElement => {
        const items: React.ReactElement[] = [];

        forEach(
            sortBy(countrySites, function (c) {
                return c.name;
            }),
            function (site: Site) {
                items.push(SiteItem(site));
            }
        );

        return (
            <OptGroup key={countrySites[0].country} label={countrySites[0].country}>
                {items}
            </OptGroup>
        );
    }, []);

    const filter = (inputValue: string, grouping: any): boolean => {
        try {
            const searchTerm = inputValue.toLowerCase();

            if (grouping.label) return (grouping.label as string).toLowerCase().includes(searchTerm);
            if (grouping.children) return (grouping.children as string).toLowerCase().includes(searchTerm);
        } catch (error) {
            console.warn(error);
        }
        return false;
    };

    const countrySites = useMemo(() => {
        return groupBy(
            sortBy(props.sites, function (c) {
                return c.country;
            }),
            (x) => x.country
        );
    }, [props.sites]);

    const items = useMemo(() => {
        const retValue = Array<React.ReactElement>();

        //closest first
        if (props.closest) {
            const closestItem = SiteItem(props.closest, true);
            const closestGroup = (
                <OptGroup key={`${props.closestVenueLabel}-nearest-group`} label={`${props.closestVenueLabel}`}>
                    {closestItem}
                </OptGroup>
            );
            retValue.push(closestGroup);
        }

        forEach(countrySites, function (country: Site[]) {
            retValue.push(CountryGroup(country));
        });

        return retValue;
    }, [CountryGroup, countrySites, props.closest, props.closestVenueLabel]);

    const onDropdownOpen = (open: boolean) => {
        const node = scrollRef.current;
        if (open && node) {
            const scrollIntoViewSmoothly =
                'scrollBehavior' in document.documentElement.style ? scrollIntoView : smoothScrollIntoView;

            scrollIntoViewSmoothly(node, { behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <section ref={scrollRef}>
            <Select
                data-testid="site-dropdown"
                showSearch
                filterOption={filter}
                onSelect={props.onChange}
                value={props.selectedSite?.siteId}
                className="zl-site-dropdown"
                dropdownClassName="zl-site-dropdown-menu"
                size="large"
                suffixIcon={<CaretDownFilled />}
                onDropdownVisibleChange={onDropdownOpen}
                placeholder={props.placeholder}
            >
                {items}
            </Select>
        </section>
    );
};

export default React.memo(SiteDropdown);
