import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { IGiftVoucherConfig } from '../../interfaces/IGiftVoucherConfig';
import VoucherAmountButton from './voucher-amount-button';
import './voucher-amount-picker.scss';

export interface IVoucherAmountPickerProps {
    config?: IGiftVoucherConfig | undefined; 
    defaultSelected?: number;
    currencySymbol: string;
    min: number;
    max: number;
    locale: string;
    onChange?: (changedValue: number, changedNumber: number) => void;
}

const VoucherAmountPicker: React.FunctionComponent<IVoucherAmountPickerProps> = (props) => {
    const memoButtons = useMemo(() => {
        const buttons: Array<React.ReactNode> = [];
        for (let index: number = props.min; index < props.max + 1; index++) {
            buttons.push(
                <VoucherAmountButton
                    key={index}
                    number={index}
                    selected={index === props.defaultSelected}
                    disabled={props.max !== undefined && index > props.max}
                    onClick={props.onChange}
                    voucherConfig={props.config}
                    currencySymbol={props.currencySymbol}
                    locale={props.locale}
                />
            );
        }
        return buttons;
    }, [props]);

    return (
        <section>
            <Row justify="start" align="top" className="zl-voucher-amount-picker-container">
                <Col span={24}>{memoButtons}</Col>
            </Row>
        </section>
    );
};

export default VoucherAmountPicker;
