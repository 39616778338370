import { Action, Reducer } from 'redux';

import { IAnalyticsAction, IAppThunkAction } from './';
import { IGoogleReverseGeoCode, IGoogleReverseGeoCodeResult } from '../interfaces/IGoogleReverseGeoCode';
import errorHelpers from '../helpers/errors';
import Coords from '../interfaces/ICoords';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';

export interface ILocationState {
    loading: boolean;
    locationFailed: boolean;
    locationKnown: boolean;
    location?: Coords;
    reverseGeoCode?: IGoogleReverseGeoCodeResult;
}

export const LOCATION_REQUESTED: 'LOCATION_REQUESTED' = 'LOCATION_REQUESTED';
export const LOCATION_RECEIVED: 'LOCATION_RECEIVED' = 'LOCATION_RECEIVED';
export const LOCATION_FAILED: 'LOCATION_FAILED' = 'LOCATION_FAILED';
export const REVERSE_GEOCODE_REQUESTED: 'REVERSE_GEOCODE_REQUESTED' = 'REVERSE_GEOCODE_REQUESTED';
export const REVERSE_GEOCODE_RECEIVED: 'REVERSE_GEOCODE_RECEIVED' = 'REVERSE_GEOCODE_RECEIVED';
export const REVERSE_GEOCODE_FAILED: 'REVERSE_GEOCODE_FAILED' = 'REVERSE_GEOCODE_FAILED';

export interface ILocationRequestedAction extends IAnalyticsAction {
    type: typeof LOCATION_REQUESTED;
}

export interface ILocationReceivedAction {
    type: typeof LOCATION_RECEIVED;
    location: Coords;
}

export interface ILocationFailedAction {
    type: typeof LOCATION_FAILED;
    failureCode: number;
}

export interface IReverseGeoCodeRequestAction {
    type: typeof REVERSE_GEOCODE_REQUESTED;
}

export interface IReverseGeoCodeReceivedAction {
    type: typeof REVERSE_GEOCODE_RECEIVED;
    data: IGoogleReverseGeoCode;
}

export interface IReverseGeoCodeFailedAction {
    type: typeof REVERSE_GEOCODE_FAILED;
    failure: string;
}

export type KnownLocationActions =
    | ILocationRequestedAction
    | ILocationReceivedAction
    | ILocationFailedAction
    | IReverseGeoCodeRequestAction
    | IReverseGeoCodeFailedAction
    | IReverseGeoCodeReceivedAction;

export const actionCreators = {
    postGetLocationAction: (): IAppThunkAction<KnownLocationActions> => (dispatch) => {
        if (!navigator.geolocation) return;

        dispatch({ type: LOCATION_REQUESTED, 
            googleTagManagerEvent: GoogleTagManagerEvents.LocationRequested() });

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const coords: Coords = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=AIzaSyD46AWK3fVUotG9dL4v-At74_m6kHsSrUc`
                )
                    .then((response) => errorHelpers.handleErrors(response))
                    .then((response) => response.json())
                    .then((data) => {
                        dispatch({
                            type: REVERSE_GEOCODE_RECEIVED,
                            data: data,
                        });
                    })
                    .catch((error: Error) => {
                        dispatch({
                            type: REVERSE_GEOCODE_FAILED,
                            failure: 'Failed to reverse Geo Code',
                        });
                    });
                dispatch({ type: LOCATION_RECEIVED, location: coords });
            },
            (error) => {
                dispatch({ type: LOCATION_FAILED, failureCode: error.code });
            }
        );
    },
};

const unloadedState: ILocationState = {
    locationKnown: false,
    location: undefined,
    locationFailed: false,
    loading: false,
    reverseGeoCode: undefined,
};

export const reducer: Reducer<ILocationState> = (
    state: ILocationState | undefined,
    incomingAction: Action
): ILocationState => {
    if (state === undefined) return unloadedState;

    const action: KnownLocationActions = incomingAction as KnownLocationActions;
    switch (action.type) {
        case LOCATION_REQUESTED:
            return {
                location: state.location,
                loading: true,
                locationFailed: state.locationFailed,
                locationKnown: state.locationKnown,
                reverseGeoCode: state.reverseGeoCode,
            };
        case LOCATION_RECEIVED:
            return {
                location: action.location,
                locationKnown: true,
                loading: false,
                locationFailed: false,
                reverseGeoCode: state.reverseGeoCode,
            };
        case LOCATION_FAILED:
            return {
                location: undefined,
                loading: false,
                locationFailed: true,
                locationKnown: false,
                reverseGeoCode: state.reverseGeoCode,
            };
        case REVERSE_GEOCODE_RECEIVED:
            return {
                location: state.location,
                locationKnown: true,
                loading: false,
                locationFailed: false,
                reverseGeoCode: action.data.results[0],
            };
        default:
            return state;
    }
};
