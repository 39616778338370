import moment from 'moment';
import Logging from '../service/logging';

import { IApplicationState } from '.';
import { BookingStatus } from './Booking';
import { languageList, ZLLanguage } from '../helpers/lang';
import { setLanguage } from '../helpers/i18n';
import { GiftVoucherStatus } from './GiftVoucher';
import { PurchaseTypes } from './PurchaseTypes';
import { History, LocationState } from 'history';

const storedCode = localStorage.getItem('i18nextLng');

const detectedLanguage = window.navigator.language;
const storedLanguage = languageList.find((language: ZLLanguage) =>
    language.code.startsWith(storedCode ?? detectedLanguage)
) ?? { code: 'en-US', rtl: false, momentLocale: 'en', displayText: 'English (US)' };

export const initState = {
    sites: {
        preselectedSite: undefined,
        selectedSite: undefined,
        selectedSiteUI: false,
        sitesLoading: true,
        sites: [],
        sitesLoaded: false,
        nearestSite: undefined,
        hasGiftVoucherAvailable: false,
    },
    games: {
        gamesLoading: false,
        games: [],
        selectedGame: undefined,
        gamesLoaded: false
    },
    players: {
        selectedPlayers: undefined,
        selectedPlayersUI: false,
        selectedPrivateUpgrade: false,
        knownPlayer: undefined,
        knownPlayerLoading: false,
    },
    booking: {
        booking: undefined,
        loading: false,
        failed: false,
        charge: undefined,
        status: BookingStatus.Unknown,
        failedMessageKey: undefined,
        validPlayerInfo: false,
        appliedCodes: [],
        emailSubscribed: false,
        smsSubscribed: false,
    },
    location: {
        location: undefined,
        loading: false,
        locationFailed: false,
        locationKnown: false,
        reverseGeoCode: undefined,
    },
    session: {
        openDatesLoaded: false,
        openDates: undefined,
        openDatesLoading: false,
        sessions: undefined,
        sessionTimes: undefined,
        selectedDate: undefined,
        sessionTimesLoaded: false,
        sessionTimesLoading: false,
        selectedSession: undefined,
        selectedDateUI: false,
        selectedTimeUI: false,
        selectedSessionUI: false,
    },
    intl: {
        currentLocale: storedLanguage?.code,
        fallbackLocale: storedLanguage?.code,
        rtl: storedLanguage?.rtl ?? false,
        momentLocale: storedLanguage?.momentLocale,
    },
    ui: {
        errorKey: undefined,
        errorMessage: undefined,
    },
    payments: {
        stripe: {
            apiKey: undefined,
            loading: false,
            success: false,
            paymentMethod: undefined,
        },
        paypal: {
            apiKey: undefined,
        },
        checkout: {
            apiKey: undefined,
        },
        loading: false,
        backProcessing: false,
        clientSecret: undefined,
    },
    attribution: {
        utm_Id: undefined,
        utm_campaign: undefined,
        utm_content: undefined,
        utm_medium: undefined,
        utm_source: undefined,
        utm_term: undefined,
        internal_source: undefined,
        internal_sourcePage: undefined,
        internal_componentType: undefined,
        internal_componentInternalName: undefined,
        internal_componentTitle: undefined,
        internal_buttonText: undefined
    },
    appSettings: {
        environment: "dev",
        gtmEndpoint: "",
        gtmSendToClients: false,
        logRocketId: "",
        brazeApiKey: "",
        brazeEndpoint: "",
        newsletterEmailGroup: "",
        updatesEmailGroup: "",
        offersEmailGroup: "",
        auSmsGroup: "",
        settingsLoaded: false,
    },
    purchaseType: {
        selectedPurchaseType: PurchaseTypes.Booking,
        selectedPurchaseTypeUI: false,
    },
    giftVoucher: {
        loading: false,
        configLoading: false,
        failed: false,
        configDetails: undefined,
        charge: undefined,
        giftVoucher: undefined,
        status: GiftVoucherStatus.Unknown,
        selectedFixedAmountButton: undefined,
        amount: undefined,
        toEmail: undefined,
        toFullName: undefined,
        fromEmail: undefined,
        fromFullName: undefined,
        message: undefined,
        validInfo: false,
        selectedGiftVoucherAmountUI: false,
        selectedGiftVoucherDetailsUI: false,
        toRecipientSelected: undefined,
        emailSubscribed: false,
        smsSubscribed: false,
    }
};

export const loadState = (history: History) => {
    try {

        console.log("Running load state from local storage");
        const serialisedState = localStorage.getItem('state');
        const serialisedLastSeen = localStorage.getItem('lastSeen');

        if (serialisedState === null || serialisedLastSeen === null) {
            moment.locale(initState.intl.momentLocale);
            return initState;
        }

        //if we loading the processing page don't clear the state
        //would be nice to be able to figure out if the previous page was something different
        let userMakingPaymentTransition = false;
        let returnClearState = false;
        let returnAttributionData = false;
        if (history.location.pathname === '/processing') {
            userMakingPaymentTransition = true;
        }

        //figure out if we should keep the local state or if it is due to be cleared/reset
        const state: IApplicationState = JSON.parse(serialisedState);
        const staleMinutes = Number(process.env.REACT_APP_STALE_STATE_TIMER) ?? 20;
        const minutes = moment().utc().diff(moment(serialisedLastSeen), 'minutes');

        //After X minutes we throw away the booking state and return the default values
        //After X days we also throw out the marketing attribution data
        if (minutes > staleMinutes) {
            const attributionStaleDays = Number(process.env.REACT_APP_STALE_ATTRIBUTION_DAYS) ?? 30;
            const days = moment().utc().diff(moment(serialisedLastSeen), 'days');

            console.log('state is stale');
            returnClearState = true;

            // if the marketing isn't too old, make sure it's preserved
            if (days < attributionStaleDays) {
                returnAttributionData = true;
            }
            else {
                console.log('state attribution data is stale');
            }
        }
        //if we have booking or gift voucher that is not 'in progress' (eg already completed or failed) 
        //return the blank slate with marketing attribution data
        else if (state.purchaseType.selectedPurchaseType === PurchaseTypes.Booking  ||
            state.purchaseType.selectedPurchaseType === PurchaseTypes.PrivateEvent) {
            if (
                !state.booking.booking ||
                state.booking.status === BookingStatus.Completed ||
                state.booking.status === BookingStatus.Failed ||
                state.booking.status === BookingStatus.Reserved
            ) {
                console.log(`booking state is ${state.booking.status} and should be cleared`);
                returnClearState = true;
                returnAttributionData = true;
            }
        }
        else if (state.purchaseType.selectedPurchaseType === PurchaseTypes.GiftVoucher) {
            if (
                !state.giftVoucher.giftVoucher ||
                state.giftVoucher.status === GiftVoucherStatus.Completed ||
                state.giftVoucher.status === GiftVoucherStatus.Cancelled ||
                state.giftVoucher.status === GiftVoucherStatus.Failed ||
                state.giftVoucher.status === GiftVoucherStatus.Reserved
            ) {
                console.log(`gift voucher state is ${state.giftVoucher.status} and should be cleared`);
                returnClearState = true;
                returnAttributionData = true;
            }
        }

        //return the blank state,
        if (returnClearState) {
            if (userMakingPaymentTransition) {
                //unless we are making some delayed payment transition(eg stripe 3Ds)
                //where the user has just successfully paid from something we had thought was expired or abandoned
                console.log('User making payment after local state should have expired, preserving state.');
                let newError = new Error("User making payment after local state should have been cleared");
                newError.name = 'Late Payment State Preservation';
                Logging.LogError(newError);
            }
            else {
                if (returnAttributionData) {
                    console.log('Returning clear state + attribution');
                    return {
                        ...initState,
                        attribution: state.attribution,
                        booking: {
                            ...initState.booking,
                            priceSets: state.booking.priceSets,
                        }
                    };
                } else {
                    console.log('Returning clear state');
                    return {
                        ...initState,
                        booking: {
                            ...initState.booking,
                            priceSets: state.booking.priceSets,
                        }
                    };
                }
            }
        }


        //otherwise return the state that we already have as it should still be relevant
        //either because it's still fresh or because the user is finalizing a stripe 3Ds payment 
        //and we need the details to show them the result of their transaction
        state.ui.errorKey = undefined;
        state.ui.errorMessage = undefined;

        if (storedLanguage !== null) {
            state.intl.rtl = storedLanguage.rtl;
            state.intl.momentLocale = storedLanguage.momentLocale;
            state.intl.currentLocale = storedLanguage.code;
            setLanguage(storedLanguage);
        }

        console.log('Returning previous state');
        return state;
    }
    catch (err) {
        console.log('state error, clearing state: ' + err);
        if (err instanceof Error) {
            let newError = new Error(err.message);
            newError.name = 'State loading error';
            Logging.LogError(newError);
        }
        else {
            let newError = new Error("Unknown exception occurred while trying to load state");
            newError.message = 'State loading error';
            Logging.LogError(newError);
        }
        return initState;
    }
};

export const saveState = (state: IApplicationState) => {
    try {
        const serialisedState = JSON.stringify(state);
        const date = moment().utc().toISOString();
        localStorage.setItem('state', serialisedState);
        localStorage.setItem('lastSeen', date);
        localStorage.setItem('i18nextLng', state.intl.currentLocale);
    } catch (err) {
        console.log(err);
    }
};
