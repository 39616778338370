import './index.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import throttle from 'lodash/throttle';
import { Provider as ReduxProvider } from 'react-redux';
import ZLLoader from './components/loader/loader';
import configureStore from './store/configureStore';
import { loadState, saveState } from './store/localStorage';
import { IApplicationState } from './store';
import { Layout } from 'antd';
import App from './App';
import Nav from './components/nav/nav';

import('./i18n');

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history, loadState(history));

store.subscribe(
    throttle(() => {
        const state = store.getState();
        saveState({
            sites: {
                accessCode: state.sites.accessCode,
                selectedSite: state.sites.selectedSite,
                selectedSiteGtmKey: state.sites.selectedSiteGtmKey,
                selectedSiteUI: state.sites.selectedSiteUI,
                bookingOptions: state.sites.bookingOptions
            },
            players: {
                selectedPlayers: state.players.selectedPlayers,
                selectedPlayersUI: state.players.selectedPlayersUI,
                selectedPrivateUpgrade: state.players.selectedPrivateUpgrade
            },
            session: {
                selectedDate: state.session.selectedDate,
                selectedTime: state.session.selectedTime,
                selectedSession: state.session.selectedSession,
            },
            ui: {},
            payments: {
                loading: false,
                clientSecret: state.payments.clientSecret,
            },
            booking: {
                booking: state.booking.booking,
                charge: state.booking.charge,
                appliedCodes: state.booking.appliedCodes,
                status: state.booking.status,
                fullName: state.booking.fullName,
                emailAddress: state.booking.emailAddress,
                phoneNumber: state.booking.phoneNumber,
                countryCode: state.booking.countryCode,
                validPlayerInfo: state.booking.validPlayerInfo,
                loading: false,
                failed: false,
                priceSets: state.booking.priceSets,
                emailSubscribed: state.booking.emailSubscribed,
                smsSubscribed: state.booking.smsSubscribed,
            },
            intl: {
                currentLocale: state.intl.currentLocale,
                fallbackLocale: state.intl.fallbackLocale,
                rtl: state.intl.rtl,
                momentLocale: state.intl.momentLocale,
            },
            purchaseType: {
                selectedPurchaseType: state.purchaseType.selectedPurchaseType,
                selectedPurchaseTypeUI: state.purchaseType.selectedPurchaseTypeUI,
            },
            giftVoucher: {

                loading: false,
                failed: false,
                configLoading: false,
                configDetails: state.giftVoucher.configDetails,
                charge: state.giftVoucher.charge,
                giftVoucher: state.giftVoucher.giftVoucher,
                status: state.giftVoucher.status,
                selectedFixedAmountButton: state.giftVoucher.selectedFixedAmountButton,
                amount: state.giftVoucher.amount,
                toEmail: state.giftVoucher.toEmail,
                toFullName: state.giftVoucher.toFullName,
                fromEmail: state.giftVoucher.fromEmail,
                fromFullName: state.giftVoucher.fromFullName,
                message: state.giftVoucher.message,
                countryCode: state.giftVoucher.countryCode,
                callingCode: state.giftVoucher.callingCode,
                phoneNumber: state.giftVoucher.phoneNumber,
                validInfo: state.giftVoucher.validInfo ?? false,
                selectedGiftVoucherAmountUI: state.giftVoucher.selectedGiftVoucherAmountUI ?? false,
                selectedGiftVoucherDetailsUI: state.giftVoucher.selectedGiftVoucherDetailsUI ?? false,
                toRecipientSelected: state.giftVoucher.toRecipientSelected,
                emailSubscribed: state.giftVoucher.emailSubscribed,
                smsSubscribed: state.giftVoucher.smsSubscribed,
            },
            attribution: {
                utm_Id: state.attribution.utm_Id,
                utm_campaign: state.attribution.utm_campaign,
                utm_content: state.attribution.utm_content,
                utm_medium: state.attribution.utm_medium,
                utm_source: state.attribution.utm_source,
                utm_term: state.attribution.utm_term,
                internal_source: state.attribution.internal_source,
                internal_sourcePage: state.attribution.internal_sourcePage,
                internal_componentType: state.attribution.internal_componentType,
                internal_componentInternalName: state.attribution.internal_componentInternalName,
                internal_componentTitle: state.attribution.internal_componentTitle,
                internal_buttonText: state.attribution.internal_buttonText
            }
        } as IApplicationState);
    }, 1000)
);

(async () => {
    const LDProvider = await asyncWithLDProvider({ clientSideID: process.env.REACT_APP_LAUNCH_DARKLY ?? '' });

    ReactDOM.render(
        <ReduxProvider store={store}>
            <ConnectedRouter history={history}>
                <LDProvider>
                    <Suspense fallback={<ZLLoader />}>
                        <Layout>
                            <Nav />
                            <App />
                        </Layout>
                    </Suspense>
                </LDProvider>
            </ConnectedRouter>
        </ReduxProvider>,
        document.getElementById('root')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
