import './processing.scss';

import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState } from '../../store';

import Heading from '../../components/heading/heading';

import { actionCreators as PaymentActions, PaymentMethodTypes } from '../../store/Payments';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router';
import useQuery from '../../hooks/useQuery';
import { push } from 'connected-react-router';
import { actionCreators as BookingActions } from '../../store/Booking';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';
import adBlockChecker from '../../helpers/adBlockChecker';
import { PurchaseTypes } from '../../store/PurchaseTypes';

const ProcessingPage: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const query = useQuery();
    const [submittedAsyncBooking, setSubmittedAsyncBooking] = useState(false);
    const backProcessing = useSelector((state: IApplicationState) => state.payments.backProcessing);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const clientSecret = useSelector((state: IApplicationState) => state.payments.clientSecret);

    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    let adBlockDetected = false;
    adBlockChecker.IsAdBlockEnabled().then((flag) => {
        adBlockDetected = flag;
    });

    useEffect(() => {
        const redirectStatus = query.get('redirect_status');
        const paymentIntent = query.get('payment_intent');
        const paymentIntentClientSecret = query.get('payment_intent_client_secret');
        
        if (paymentIntent && paymentIntentClientSecret && redirectStatus) {
            console.log('redirectStatus: ' + redirectStatus + ", submittedBookingAsync: " + submittedAsyncBooking);
            if (redirectStatus === 'failed') {
                console.log('redirect status is FAILED, push user to checkout');
                dispatch(push(`/checkout/${selectedSite?.urlKey}`));
            } else if (redirectStatus === 'succeeded' && !submittedAsyncBooking) {
                console.log('redirect status is SUCCEEDED, and not submitted async booking');
                if(paymentIntentClientSecret == clientSecret) {
                    setSubmittedAsyncBooking(true);
                    if (Number(process.env.REACT_APP_CONFIRM_PAYMENTS) === 1) {
                        if (selectedPurchaseType === PurchaseTypes.Booking || selectedPurchaseType === PurchaseTypes.PrivateEvent) {
							console.log('attempt confirm booking or private event');
                            dispatch(BookingActions.confirmBooking({ StripeChargeId: paymentIntent }, paymentIntent, Number(PaymentMethodTypes.Stripe), adBlockDetected));
                        }
                        else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
                            console.log('attempt confirm gift voucher');
                            dispatch(GiftVoucherActions.confirmGiftVoucher({ StripeChargeId: paymentIntent }, paymentIntent, Number(PaymentMethodTypes.Stripe), adBlockDetected));
                        }
                        else {
                            console.error("No Purchase type was selected when trying to confirm purchase");
                        }                     
                    }
                } else {
                    console.log(`Client Secret doesn't match! Stored: ${clientSecret}, Payment Secret: ${paymentIntentClientSecret}`);
                    dispatch(BookingActions.clearBooking());
                    dispatch(GiftVoucherActions.clearGiftVoucher());
                    if (selectedSite?.urlKey) {
                        console.log('redirect status is FAILED, push user to book now');
                        dispatch(push(`/book-now/${selectedSite?.urlKey}`));
                    } else {
                        console.log('processing pushing to /');
                        dispatch(push('/'));
                    }
                }
            }
        }
    }, [dispatch, query, clientSecret]);

    useEffect(() => {
        if(!backProcessing) {
            dispatch(PaymentActions.backProcessingAdded());

            // Clone location object and push it to history
            history.push({
                pathname: location.pathname,
                search: location.search
            });
        }
      }, []);

    useEffect(() => {
        return history.listen((newLocation, action) => { // listen
            if (action === "POP") {
                console.log("action is POP")
                history.go(1);
            }
        });
      }, [history]);

    return (
        <React.Fragment>
            <Row justify="center" align="middle" className="processing-container">
                <Col className="text-center">
                    <Heading level={1} className="massive">
                        <LoadingSpinner />
                    </Heading>
                    <Heading level={2} className="massive">
                        {t('pageTitles.processingBooking')}
                    </Heading>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProcessingPage;
