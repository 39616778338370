import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';

export interface IRecipientInfoForm {
    prefilledEmail?: string;
    prefilledName?: string;
    prefilledMessage?: string;
    hidden: boolean;
}

const VoucherDetailInput: React.FunctionComponent<IRecipientInfoForm> = (props) => {
    const { t } = useTranslation();
    const {
        prefilledEmail,
        prefilledName,
        prefilledMessage,
        hidden,
    } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [emailAddress, setEmailAddress] = useState(prefilledEmail);
    const [fullName, setFullName] = useState(prefilledName);
    const [message, setMessage] = useState(prefilledMessage);

    const [emailValid, setValidEmail] = useState(prefilledEmail !== undefined);
    const [fullNameValid, setValidFullName] = useState(prefilledName !== undefined);

    const emailChanged = (email: string) => {
        setEmailAddress(email);
    };

    const fullNameChanged = (fullName: string) => {
        setFullName(fullName);
    };

    const messageChanged = (message: string) => {
        if (message.trim() !== "") {
            setMessage(message);
        }
    }

    useEffect(() => {
        if (emailValid && fullNameValid) {
            dispatch(GiftVoucherActions.validGiftVoucher(true));
        } else dispatch(GiftVoucherActions.validGiftVoucher(false));
    }, [emailValid, fullNameValid, dispatch]);

    useEffect(() => {
        let timer: any = null;
        timer = setInterval(() => {
            if (emailAddress) {
                form.validateFields(['emailAddress'])
                    .then((values) => {
                        setValidEmail(true);
                        dispatch(GiftVoucherActions.recordToEmailAddress(emailAddress));
                    })
                    .catch((errorInfo) => {
                        setValidEmail(false);
                    });
            }
            clearInterval(timer);
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [emailAddress]);

    useEffect(() => {
        let timer: any = null;
        timer = setInterval(() => {
            if (fullName) {
                form.validateFields(['fullName'])
                    .then((values) => {
                        setValidFullName(true);
                        dispatch(GiftVoucherActions.recordToFullName(fullName));
                    })
                    .catch((errorInfo) => {
                        setValidFullName(false);
                    });
            }
            clearInterval(timer);
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [fullName]);

    useEffect(() => {
        let timer: any = null;
        timer = setInterval(() => {
            if (message) {
                form.validateFields(['message'])
                    .then((values) => {
                        dispatch(GiftVoucherActions.recordMessage(message));
                    })
                    .catch((errorInfo) => {
                        
                    });
            }
            clearInterval(timer);
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [message]);

    const fullNameValidationRules: Array<any> = [
        {
            required: true,
            max: 50,
            pattern: /^(?!\s*$).+/,
            message: 'Fullname required',
        },
    ];

    const emailAddressValidationRules: Array<any> = [
        { required: true, message: 'Email required' },
        { type: 'email', message: 'Incorrect email format' },
    ];

    return (
        <React.Fragment > 
            <Form
                form={form}
                colon={false}
                initialValues={{
                    emailAddress: prefilledEmail,
                    fullName: prefilledName,
                    message: prefilledMessage,
                }}
                layout="horizontal"
                name="player-info-form"
                hidden={hidden}
            >
                <Row align="top" justify="start" gutter={[0, 24]}>
                    <Col span={24}>
                        <Form.Item
                            name="emailAddress"
                            className={`zl-form-item email show-label`}
                            hasFeedback={true}
                            rules={emailAddressValidationRules}
                        >
                            <Input
                                className="zl-input"
                                type="email"
                                onChange={(element) => emailChanged(element.currentTarget.value)}
                                placeholder={t('giftVoucherSection.details.emailLabel')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="fullName"
                            className={`zl-form-item show-label`}
                            hasFeedback={true}
                            rules={fullNameValidationRules}
                        >
                            <Input
                                className="zl-input"
                                onChange={(element) => fullNameChanged(element.currentTarget.value)}
                                placeholder={t('giftVoucherSection.details.nameLabel')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="message"
                            className={`zl-form-item show-label`}
                        >
                            <TextArea
                                rows={4}
                                maxLength={250}
                                onChange={(element) => messageChanged(element.currentTarget.value)}
                                placeholder={t('giftVoucherSection.details.messageLabel')}
                            >
                            </TextArea>
                        </Form.Item>
                    </Col>
               
                </Row>
            </Form>
        </React.Fragment>
    )
};

export default VoucherDetailInput;